import Image from 'next/legacy/image'
import { ImageWrapper } from '@/src/modules/not-found/StyledComponents'

const NotFoundImage = () => {
    return (
        <ImageWrapper>
            <Image
                src={'/images/404_illustration.png'}
                layout={'intrinsic'}
                height={658}
                width={586}
                alt={'404 page illustration'}
                data-testid={'404Image'}
            />
        </ImageWrapper>
    )
}

export default NotFoundImage
