import getBuilderPromoBanners from '../src/common/queries/getBuilderPromoBanners'
import Link from 'next/link'
import { getBuilderBannersOrPromos } from '@/src/common/queries/getBuilderBannersOrPromos'

import {
    PageWrapper,
    TextWrapper,
} from '@/src/modules/not-found/StyledComponents'
import NotFoundImage from '@/src/modules/not-found/NotFoundImage'
import NotFoundText from '@/src/modules/not-found/NotFoundText'
import { defaultNotFoundContent } from '@/src/modules/not-found/constants'
import { FAQ_PAGE } from '@/src/modules/pg-plus/constants'

export default function Custom404() {
    return (
        <PageWrapper data-testid={'404'}>
            <TextWrapper>
                <NotFoundText />
                {/* <Link href={'/visa-gift-cards'}>Visa gift cards</Link> */}
                <Link href={'/brands'}>Brand eGift cards</Link>
                <Link href={'/corporate'}>Corporate &#38; bulk</Link>
                {/* <Link href={'/activate'}>Activate a card</Link> */}
                <Link href={'/sitemap.html'}>Sitemap</Link>
                <Link href={FAQ_PAGE}>Frequently asked questions</Link>
            </TextWrapper>
            <NotFoundImage />
        </PageWrapper>
    )
}

export async function getStaticProps() {
    const { corporateBanner } = await getBuilderPromoBanners()
    const { consumerData: consumerBanner } = await getBuilderBannersOrPromos(
        true
    )

    return {
        props: {
            consumerBanner,
            corporateBanner,
            content: defaultNotFoundContent,
        },
        // Next.js will attempt to re-generate the page:
        // - When a request comes in
        // - At most once every 5 seconds
        revalidate: true,
    }
}
