import { GradientText } from '@/src/common/components/marketing/heros/StyledComponents'
import {
    BlueLine,
    StyledH1,
    StyledText,
} from '@/src/modules/not-found/StyledComponents'

const NotFoundText = () => {
    return (
        <>
            <BlueLine />
            <StyledH1 data-testid={'h1Text404'}>
                <GradientText>Sorry,</GradientText> we can&apos;t find that
                page.
            </StyledH1>
            <StyledText>
                Looks like this URL no longer exists. Try navigating by choosing
                one of the links below.
            </StyledText>
        </>
    )
}

export default NotFoundText
