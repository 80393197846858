import { styled } from '@/src/stitches.config'

export const PageWrapper = styled('div', {
    width: '$default',
    maxWidth: '$defaultMax',
    minHeight: '70vh',
    display: 'grid',
    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
    margin: 'auto',
    gap: 30,
    py: 20,
    '@media (min-width: 650px)': {
        py: 40,
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },
})

export const TextWrapper = styled('div', {
    flex: 0.8,
    display: 'flex',
    flexDirection: 'column',
    '& a': {
        lineHeight: '35px',
        color: '$primary',
        textDecoration: 'underline',
    },
    '@media (min-width: 650px)': {
        width: 425,
        maxWidth: '100%',
    },
})

export const StyledText = styled('p', {
    lineHeight: '1.75rem',
    fontSize: '1.15rem',
    mt: 0,
})

export const StyledH1 = styled('h1', {
    fontWeight: 'bold',
    lineHeight: '3rem',
    fontSize: '2.45rem',
})

export const BlueLine = styled('div', {
    borderBottom: '3px solid $secondary',
    width: 65,
    height: 3,
})

export const ImageWrapper = styled('div', {
    flex: 0.4,
    width: '100%',
    maxWidth: 350,
    height: 'auto',
    margin: 'auto',
    '@media (min-width: 650px)': {
        maxWidth: 425,

        margin: 'auto 0px auto auto',
    },
})
